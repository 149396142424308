.breadcrumb-item {

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {

    &::before {
      position: relative;
      top: -.225rem;
      vertical-align: top;
    }
  }

  > a {
    color: $breadcrumb-divider-color;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      background-color: $gray-200;
    }
  }

  &.active {
    font-weight: $breadcrumb-active-font-weight;
  }
}
