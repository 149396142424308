//
// Navbar Sizes
//

.navbar-lg {
  @include navbar-size($navbar-padding-y-lg);
}

.navbar-sm {
  @include navbar-size($navbar-padding-y-sm);

  @include font-size($font-size-sm);
  line-height: 1.1;

  .btn,
  .form-control {
    height: 1.625rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    @include font-size($font-size-sm);
    line-height: $line-height-sm;
  }

  .navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    @include font-size($font-size-base);
  }

  .nav-link {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}
