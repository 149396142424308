//
// Base styles
//

.card {
  @extend %font-setting-base;
  @include box-shadow($shadow-2dp);
}

.card-title {
  @include font-size($h4-font-size);
  font-weight: $font-weight-normal;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 1.5;
  @include font-size($font-size-sm);
  font-weight: $font-weight-normal;
}
